import axios from 'axios';
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();
const svodApi = axios.create({
    baseURL: 'https://svod.polyanaski.ru/api/market',
    headers: {
        'mrktsid': cookies.get('server_sid') || "",
        // 'd_tk': cookies.get('testMode') ? (crypto ? crypto.randomUUID() : '') : null,
        'd_tk': cookies.get('testMode') ? 'c63d5e3f-f3db-4aac-be71-20af9e72fc4c' : null,
    }
});

svodApi.defaults.headers.post['Content-Type'] = 'application/json';

export {svodApi};