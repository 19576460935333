import axios from "axios";
import {getHost} from "@/hooks/fnHelper";
import {useCookies} from "vue3-cookies";
import {svodApi} from "@/api/svodApi";

export const initAppPlugin = async (app) => {
    const {cookies} = useCookies();

    const getServerSid = async (tries = 0) => {
        const host = getHost();
        if (!cookies.isKey('server_sid')) {
            try {
                const {data: apiData} = await axios.get('https://api.polyanaski.ru/API/');
                if (!apiData?.session?.sid) {
                    throw new Error('No sid');
                }
                cookies.set('server_sid', apiData.session.sid, "/", host);
            } catch (e) {
                if (tries < 5) {
                    return await getServerRoutes(++tries);
                }
            }
        }
    }

    const getServerRoutes = async () => {
        try {
            const {data: serverRouters} = await svodApi.get('/salegroups', {
                timeout: 4000
            });

            app.config.globalProperties.$serverRouters = serverRouters;
        } catch (e) {
            console.log('useServerRouters e', e)
            throw e;
        }
    }

    await getServerSid();
    await getServerRoutes();
}