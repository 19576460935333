import {useCookies} from 'vue3-cookies';

export default {
    install: async (app, options) => {
        const {cookies} = useCookies();
        let urlObj = null;
        let referrer = null;
        try {
            urlObj = new URL(document.referrer);
        } catch (e) {
        }

        if (urlObj && urlObj.host) {
            if (['alpikahotel.ru', 'polyanaski.local'].includes(urlObj.host)) {
                referrer = 'alpikahotel';
                cookies.set('referrer', referrer);
            } else if (!['market.polyanaski.ru', 'localhost:5175', 'ski-pass.polyanaski.ru'].includes(urlObj.host)) {
                referrer = null;
                cookies.remove('referrer');
            }
        }

        app.config.globalProperties.$referrer = referrer; //cookies.get('referrer') ?? null;
    }
}