<script>
import moment from 'moment';
import {skiPassApi} from "@/api/skiPassApi";
export default {
    props: {
        callback: {
            type: Function || null,
            default: null
        }
    },
    data() {
        return {
            code: '',
            loading: false,
            repeatCodeTimer: 0,
            repeatCodeTimerInt: null,
            needCode: true,
            email: '',
        }
    },

    methods: {
        getConfirmEmailCode() {
            if (this.loading || this.repeatCodeTimer > 0) {
                return;
            }

            if (!this.email) {
                this.$toast.warning('Необходимо ввести email адрес.');
                setTimeout(() => {
                    this.$refs.email.focus();
                });
                return;
            }

            this.loading = true;

            skiPassApi.get('/' + (this.$root.testMode ? 'bookingd' : 'booking') + '/API?paramline=' +
                btoa(JSON.stringify({
                    "cmd": "setemail",
                    "email": this.email.toLowerCase(),
                    "mrktsid": this.$cookies.get('server_sid')
                })))
                .then(({data}) => {
                    if (data.iResult === 0) {
                        this.needCode = false;
                        this.repeatCodeTimer = 90;

                        setTimeout(() => {
                            this.$refs.code.focus();
                        });

                        this.repeatCodeTimerInt = setInterval(() => {
                            this.repeatCodeTimer--;
                            if (this.repeatCodeTimer <= 0) {
                                this.repeatCodeTimer = 0;
                                clearInterval(this.repeatCodeTimerInt);
                            }
                        }, 1000);
                    } else {
                        if (data?.Exceptions?.[0]?.Description) {
                            this.$toast.warning(data.Exceptions[0].Description);
                        } else {
                            this.$toast.warning(data.Description);
                        }
                    }
                }).finally(() => {
                this.loading = false;
            });
        },
        confirmEmail() {
            if (!this.code) {
                this.$toast.warning('Укажите проверочный код.');
                return;
            }
            if (this.loading) {
                return;
            }

            this.loading = true;

            skiPassApi.get('/' + (this.$root.testMode ? 'bookingd' : 'booking') + '/API?paramline=' +
                btoa(JSON.stringify({
                    "cmd": "confirmemail",
                    "code": this.code,
                    "mrktsid": this.$cookies.get('server_sid')
                })))
                .then(({data}) => {
                    if (data.iResult === 0) {
                        this.$root.user.email = this.email;
                        this.$root.user.date_confirm_email = parseInt((moment().utc() / 1000) + 3600 * 24);
                        this.$toast.success('Email адрес успешно подтвержден!');

                        setTimeout(() => {
                            if (this.callback instanceof Function) {
                                this.callback();
                            }
                            this.$emit('close');
                        });
                    } else {
                        if (data?.Exceptions?.[0]?.Description) {
                            this.$toast.warning(data.Exceptions[0].Description);
                        } else {
                            this.$toast.warning(data.Description);
                        }
                    }
                }).finally(() => {
                this.loading = false;
            });
        },
    }
}
</script>

<template>
    <div class="popup popup-auth" data-id="auth">
        <div class="popup-container auth">
            <div class="btn-close" @click="$emit('close')">
                <svg class="icon icon-close">
                    <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-close"></use>
                </svg>
            </div>
            <div id="auth">
                <div class="auth-title">Укажите адрес электронной почты</div>
                <div class="auth-description" v-if="needCode">
                    Для продолжения покупки, пожалуйста, укажите адрес действительной электронной почты для получения электронных чеков
                </div>
                <div class="auth-description" v-else>
                    Код подтверждения отправлен <br> на email адрес <u>{{ email }}</u>
                </div>
                <form action="#" method="post" class="auth-form auth-form-send-sms">
                    <template v-if="needCode">
                        <div class="mail-box">
                            <svg class="icon icon-mail">
                                <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-mail"></use>
                            </svg>
                            <input type="text" name="email" ref="email" v-model="email"
                                   placeholder="Email" autofocus>
                        </div>
                        <button class="btn btn-dark" :class="{'loading': loading}"
                                @click.prevent="getConfirmEmailCode">
                            Получить код
                        </button>
                    </template>
                    <template v-else>
                        <div class="mail-box">
                            <svg class="icon icon-mail">
                                <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-mail"></use>
                            </svg>
                            <input type="text" name="code" ref="code" inputmode="numeric" v-model="code"
                                   placeholder="Код подтверждения" autofocus>
                        </div>
                        <div class="auth-form-repeat-code">
                            <template v-if="repeatCodeTimer<=0">
                                <a href @click.prevent="getConfirmEmailCode">отправить еще раз</a>
                                <a href @click.prevent="needCode=true">изменить email</a>
                            </template>
                            <a href v-else @click.prevent>Получить новый код можно через {{ repeatCodeTimer }} сек.</a>
                        </div>
                        <div class="buttons">
                            <a href class="btn btn-dark" :class="{'loading': loading}"
                               @click.prevent="confirmEmail">Подтвердить</a>
                        </div>
                    </template>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
