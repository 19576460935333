<script>
import {apiErrorHandler} from "@/hooks/fnHelper";
import {svodApi} from "@/api/svodApi";

export default {
    name: "SbpQrCodePopup",
    props: {
        link: String,
        orderUID: String,
        amount: Number,
        successFn: {
            type: Function || null,
            default: null
        },
        failFn: {
            type: Function || null,
            default: null
        }
    },
    data() {
        return {
            checkCodeEndTime: null,
            checkCodeInt: null,
            paymentFail: false,
            successStatuses: [4, 5],
            failStatuses: [-20, -5, -3, -2, -1, 6],
            isTimeOut: false
        }
    },
    mounted() {
        this.checkCodeEndTime = Date.now() + (7 * 60 * 1000);
        this.checkCodeInt = setInterval(() => {
            this.isTimeOut = Date.now() > this.checkCodeEndTime;
            if (!this.isTimeOut) {
                this.checkPaymentStatus();
            } else {
                clearInterval(this.checkCodeInt);
                this.checkCodeInt = null;
            }
        }, 3000);
    },
    methods: {
        checkPaymentStatus() {
            svodApi.get('/paycheck?OrderUID=' + this.orderUID)
                .then(({data}) => {
                    if (~this.successStatuses.indexOf(parseInt(data.iStatus))) {
                        this.successFn();
                        clearInterval(this.checkCodeInt);
                        this.checkCodeInt = null;
                        this.$emit('close');
                    } else if (~this.failStatuses.indexOf(parseInt(data.iStatus))) {
                        clearInterval(this.checkCodeInt);
                        this.checkCodeInt = null;
                        if (parseInt(data.iStatus) === -20) {
                            this.isTimeOut = true;
                        } else {
                            this.paymentFail = true;
                        }
                    }
                }).catch((error) => {
                apiErrorHandler(error, this);
            });

            /*let r = Math.random();
            console.log('r', r);
            if (r < 0.2) {
                this.successFn();
                clearInterval(this.checkCodeInt);
                this.checkCodeInt = null;
                this.$emit('close');
            } else if (r > 0.2 && r < 0.3) {
                clearInterval(this.checkCodeInt);
                this.checkCodeInt = null;
                this.paymentFail = true;
            }*/
        }
    },
    unmounted() {
        console.log('unmounted');
        clearInterval(this.checkCodeInt);
        this.checkCodeInt = null;
    }
}
</script>

<template>
    <div class="popup" data-id="sbp-qr-code">
        <div class="popup-container sbp-qr-code">
            <div class="content">
                <div class="text">
                    <div v-if="paymentFail"
                         class="sbp-qr-code__timeout"
                    >
                        <div class="sbp-qr-code__timeout-title">
                            <p>Оплата <br> не прошла!</p>
                            <svg class="icon icon-info-ex">
                                <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-warn"></use>
                            </svg>
                        </div>
                        <div class="sbp-qr-code__timeout-text">
                            <p>Для повторной оплаты закройте данное окно и снова нажмите кнопку оплаты.</p>
                        </div>
                    </div>

                    <template v-else-if="!isTimeOut">
                        <img :src="link" alt="">
                        <div v-if="amount" class="amount">
                            К оплате: <b>{{ amount.toLocaleString('ru') }}</b> ₽
                        </div>

                        <div class="head">
                            <img class="head--sbp__icon" src="@/assets/images/icon-spb.svg" alt="Система быстрых платежей">
                            <!--                            <span>Для оплаты</span>-->
                        </div>

                        <p>Для оплаты отсканируйте QR-код в мобильном приложении банка или штатной камерой телефона</p>
                    </template>

                    <div v-else
                         class="sbp-qr-code__timeout"
                    >
                        <div class="sbp-qr-code__timeout-title">
                            <p>Время ожидания оплаты истекло!</p>
                            <svg class="icon icon-info-ex">
                                <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-warn"></use>
                            </svg>
                        </div>
                        <div class="sbp-qr-code__timeout-text">
                            <p>Для повторной оплаты закройте данное окно и снова нажмите кнопку оплаты.</p>
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <button class="btn btn-dark" @click="$emit('close')">Закрыть</button>
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
